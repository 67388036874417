import {
	defineStore
} from 'pinia'

import {
	useStorage
} from '@vueuse/core'

import { 
	LangTypes
 } from '@/types' // must be imported since file contains an export statement

export const localesStore = defineStore('locales', {
	// a function that returns a fresh state
	state: () => ({
		locales: useStorage('locales', {
			en: {} as any,
			ja: {} as any,
			kana: {} as any,
			selected: 'en' as LangTypes,
		})
	}),
	
	// optional getters
	getters: {
		// the return type **must** be explicitly set
		getLabel: (state) => (field: string) => state.locales?.[state.locales.selected]?.[field]?.l || '',

		getFmLanguage: (state) => {
			switch(state.locales.selected) {
				case 'en': return "English/英語"
				case 'ja': return "Kanji/漢字"
				case 'kana': return "Kana/カナ"
				default: return "English/英語"
			}
		}
	},

	// optional actions
	actions: {

	}
})