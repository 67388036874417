<template>
	<div
		:id="labelId ?? undefined"
		data-slot="legend"
		class="text-base/6 font-semibold text-zinc-950 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-white">
		<slot />
	</div>
</template>

<script lang="ts">
	export default { name: 'VLegend' } // name the component
</script>

<script setup lang="ts">
	import {
		inject
	} from "vue"

	const labelId: string = inject('labelId', null) || ''
</script>