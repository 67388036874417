<template>
	<div
		data-slot="control"
		class="space-y-8">
		<slot />
	</div>
</template>

<script lang="ts">
	export default { name: 'VFieldGroup' } // name the component
</script>