<template>
	<div class="grid grid-cols-4 gap-4 -mb-4">
		<tw-notification 
			v-if="showNotification" 
			@close="showNotification = false" 
			:title="notificationTitle" 
			:subtitle="notificationSubtitle" 
			:duration="8000" 
			:icon="notificationIcon" />
		<div class="col-span-4">
			<tw-table
				:placeholder="t('placeholders.noUnavailability')"
				:data="props?.contact?.portalData?.portal_unavailability"
				:loading="loading">
				<!-- date -->
				<tw-table-column 
					:header="localesStore.getLabel('361')">
					<template #default="{row}">
						{{ row['contacts__DAYSOFF__contactID::date'] }}
					</template>
				</tw-table-column>
				<!-- unavailability -->
				<tw-table-column 
					:header="localesStore.getLabel('678')">
					<template #default="{row}">
						{{ row['contacts__DAYSOFF__contactID::availability_display'] }}
					</template>
				</tw-table-column>
				<!-- day -->
				<tw-table-column 
					:header="localesStore.getLabel('737')">
					<template #default="{row}">
						{{ localesStore.getLabel(row['contacts__DAYSOFF__contactID::day_code']) }}
					</template>
				</tw-table-column>
				<!-- time -->
				<tw-table-column 
					:header="localesStore.getLabel('332')">
					<template #default="{row}">
						{{ `${$filters.convertTime(row['contacts__DAYSOFF__contactID::time_start'])} - ${$filters.convertTime(row['contacts__DAYSOFF__contactID::time_end'])}` }}
					</template>
				</tw-table-column>
				<!-- Edit Button -->
				<tw-table-column>
					<template #default="{row}">
						<button 	
							class="link" 
							@click="() => { openUnavailabilityModal(row) }">
							{{ localesStore.getLabel('702') }}
						</button>
					</template>
				</tw-table-column>
				<!-- Delete Button -->
				<tw-table-column>
					<template #default="{row}">
						<button 	
							class="link" 
							@click="() => { deleteUnavailability(row['contacts__DAYSOFF__contactID::_kp_daysoff_id']) }">
							{{ "Delete" }}
						</button>
					</template>
				</tw-table-column>
			</tw-table>

			<!-- Create New Unavailability -->
			<div
				class="absolute top-0 right-5">
				<button 	
					class="link" 
					@click="openUnavailabilityModal({})">
					{{ `${t('account.submitUnavailability')}...` }}
				</button>
			</div>
			<tw-modal
				:title="Object.keys(selectedRow).length === 0 ? t('account.submitUnavailability') : t('account.editUnavailability')"
				:breadcrumb="[]"
				modal-max-width="max-w-md"
				modal-min-height="min-h-0"
				ref="unavailabilityModal">
				<unavailability-form 
					:contact="contact"
					:data="{...selectedRow}"
					@close-unavailability-modal="(notification) => {displayNotification(notification)}" />
			</tw-modal>
		</div>
	</div>
</template>


<script lang="ts">
	export default { name: 'UnavailabilityDetails' } // name the component
</script>

<script setup lang="ts">
	import UnavailabilityForm from './UnavailabilityForm.vue'

	import {
		Notification
	} from '@/types'

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore as _localesStore
	} from '@/store/locales'

	import {
		modifyUnavailability
	} from  '../mutations'

	import { // vue
		ref,
		defineEmits
	} from 'vue'
	
	const props = defineProps({
		contact: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})



	const localesStore = _localesStore()

	const {t} = useI18n()

	const unavailabilityModal = ref<{open: boolean}>({open: false})

	const selectedRow = ref<{}>({})

	const emit = defineEmits(['refreshAccountData'])

	function openUnavailabilityModal(row: Object) {
		selectedRow.value = row
		unavailabilityModal.value.open = true
	}

	const showNotification = ref(false)
	const notificationIcon = ref(0)
	const notificationTitle = ref('')
	const notificationSubtitle = ref('')
	
	function displayNotification(notification: Notification) {
		selectedRow.value = {}

		notificationIcon.value = notification.icon
		notificationTitle.value = notification.title
		notificationSubtitle.value = notification.subtitle
		unavailabilityModal.value.open = false
		showNotification.value = true
		emit('refreshAccountData')
	}
	
	async function deleteUnavailability(recordID: string) {
		const data = JSON.stringify({
			action: "delete",
			unavailabilityID: recordID,
		})

		try{
			const result = await modifyUnavailability(data)

			const scriptResult = JSON.parse(result.response.scriptResult)

			const fmpError = scriptResult.errorCode !== 0

			if (fmpError) {
				throw new Error(scriptResult.errorMessage)
			}

			const notification: Notification = {
				title: t('placeholders.success'),
				subtitle: t('account.unavailabilityDeleted'),
				icon: 0
			}

			displayNotification(notification)

		} catch (e) {
			const notification: Notification = {
				title: t('placeholders.error'),
				subtitle: t('placeholders.deleteUnavailabilityFail'),
				icon: 1
			}

			displayNotification(notification)
			console.error(e)
		}
	}
</script>