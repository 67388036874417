<template>
	<label
		:id="labelId ?? undefined"
		data-slot="label"
		:data-disabled="fieldDisabled ? true : null"
		:for="controlId"
		class="select-none text-base/6 text-zinc-950 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-white"
		@click.prevent="clickHandler()">
		<slot />
	</label>
</template>

<script lang="ts">
	export default { name: 'VLabel' } // name the component
</script>

<script setup lang="ts">
	import {
		inject
	} from "vue"

	const fieldDisabled: boolean = inject('fieldDisabled', null) || inject('fieldsetDisabled', null) || false
	const controlId: string = inject('controlId', null) || ''
	const labelId: string = inject('labelId', null) || ''

	function clickHandler() {
		document.getElementById(controlId)?.click()
	}

</script>
