<template>
	<div
		:class="clsx(
			className,
			'[&>[data-slot=label]+[data-slot=control]]:mt-3',
			'[&>[data-slot=label]+[data-slot=description]]:mt-1',
			'[&>[data-slot=description]+[data-slot=control]]:mt-3',
			'[&>[data-slot=control]+[data-slot=description]]:mt-3',
			'[&>[data-slot=control]+[data-slot=error]]:mt-3',
			'[&>[data-slot=label]]:font-medium'
		)">
		<slot />
	</div>
</template>

<script lang="ts">
	export default { name: 'VField' } // name the component
</script>

<script setup lang="ts">
	import { 
		clsx
	} from 'clsx'

	import {
		getCurrentInstance,
		provide
	} from 'vue'

	const props = defineProps({
		className: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		}
	})

	provide('fieldDisabled', props.disabled)
	provide('fieldRequired', props.required)

	const controlId = 'control-' + getCurrentInstance()?.uid
	provide('controlId', controlId)

	const labelId = 'label-' + getCurrentInstance()?.uid
	provide('labelId', labelId)
</script>
