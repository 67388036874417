import axios from 'axios'

import { 
	FindParameters
 } from '@/types' // must be imported since file contains an export statement



function getJobsByContactId(params: FindParameters) {
	return axios.post(`proxy.php?/layouts/job-list-search/_find`, params)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}
function getJobsByContactIdFilter(params: FindParameters) {
	return axios.post(`proxy.php?/layouts/job-list-search-filter/_find`, params)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getMeetingsByJobOrContactId(params: FindParameters) {
	return axios.post(`proxy.php?/layouts/meeting-list-search/_find`, params)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getRelatedJobsByJobId(params: FindParameters) {
	return axios.post(`proxy.php?/layouts/job-search/_find`, params)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getJobByRecordId(params: FindParameters) {
	// return axios.get(`proxy.php?/layouts/job-search/records/${recordId}${params}`,)
    //     .then((response) => {
    //         return response.data
    //     })
    //     .catch((e) => {
    //         if(e.response.data.messages[0].message) {
    //             // Return FM Error Response
    //             return e.response.data
    //         }else{
    //             // Format Unknown Error Like a FM Error
    //             return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
    //         }
    //     })
	return axios.post(`proxy.php?/layouts/job-search/_find`, params)
		.then((response) => {
			return response.data
		})
		.catch((e) => {
			if(e.response.data.messages[0].message) {
				// Return FM Error Response
				return e.response.data
			}else{
				// Format Unknown Error Like a FM Error
				return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
			}
		})
}

function getJobPrintInstructions(params: any) {
	const postData = {
		fieldData : {
			postData: params
		},
		script : "Print Scene Instructions",
		'script.param' : params
	}
	return axios.post(`runScript.php`, postData)
		.then((response) => {
			return response.data
		})
		.catch((e) => {
			if(e.response.data.messages[0].message) {
				// Return FM Error Response
				return e.response.data
			}else{
				// Format Unknown Error Like a FM Error
				return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
			}
		})
}

function getRoleDetailsByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-roles/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}


function getSceneDetailsByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-scenes/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getSceneDetailsRolesByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-scenes-minimal/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getSceneRolesByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-scenes-roles/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getSceneVariationRolesByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-variations/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getSceneVariationRolesInstructionsByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-variations-roles/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getSceneOptionsRolesByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-scenes-options/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getSceneOptionsRolesInstructionsByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-scenes-options-roles/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getSceneSongsByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-songs/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getSceneSongScoresByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-songs-scores/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getSceneBGMsByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-bgms/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getSceneLiveSongsByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/jobs-live/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getSceneLiveScoresByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/live-songs/records/${recordId}${params}`,)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getJobRolesByContactID(params: FindParameters) {
    return axios.post(`proxy.php?/layouts/job-role-search/_find`, params)
    .then((response) => {
        return response.data
    })
    .catch((e) => {
        if(e.response.data.messages[0].message) {
            // Return FM Error Response
            return e.response.data
        }else{
            // Format Unknown Error Like a FM Error
            return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
        }
    })
}

export { 
	getJobPrintInstructions,
	getJobsByContactId,
	getJobByRecordId,
	getSceneDetailsByRecordId,
	getSceneDetailsRolesByRecordId,
	getMeetingsByJobOrContactId,
	getRelatedJobsByJobId,
	getSceneVariationRolesByRecordId,
	getSceneVariationRolesInstructionsByRecordId,
	getSceneOptionsRolesByRecordId,
	getSceneOptionsRolesInstructionsByRecordId,
	getSceneRolesByRecordId,
	getRoleDetailsByRecordId,
	getSceneSongScoresByRecordId,
	getSceneSongsByRecordId,
	getSceneBGMsByRecordId,
	getSceneLiveSongsByRecordId,
	getSceneLiveScoresByRecordId,
	getJobsByContactIdFilter,
    getJobRolesByContactID,
}