import axios from 'axios'

function modifyUnavailability(data: string) {
	const postData = {
		fieldData : {
			postData: data
		},
		script : "Contacts - Modify Unavailability",
		'script.param' : data
	}
	return axios.post(`runScript.php`, postData)
		.then((response) => {
			return response.data
		})
		.catch((e) => {
			if(e.response.data.messages[0].message) {
				// Return FM Error Response
				return e.response.data
			}else{
				// Format Unknown Error Like a FM Error
				return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
			}
		})
}


export { modifyUnavailability }