<template>
	<span
		data-slot="control"
		:class="clsx([
			className,
			// Basic layout
			'group relative block w-full',
			// Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
			'before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white before:shadow',
			// Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
			'dark:before:hidden',
			// Focus ring
			'after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent after:has-[[data-focus]]:ring-2 after:has-[[data-focus]]:ring-blue-500',
			// Disabled state
			'has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-zinc-950/5 before:has-[[data-disabled]]:shadow-none',
		])">
		<select
			:disabled="fieldDisabled"
			:data-disabled="fieldDisabled ? true : null"
			:data-invalid="invalid ? true : null"
			:data-focus="focused ? true : null"
			:data-hover="hovered ? true : null"
			:required="required"
			:value="modelValue"
			:name="name ?? null"
			:class="clsx([
				className,
				'bg-none',
				// Basic layout
				'relative block w-full appearance-none rounded-lg py-[calc(theme(spacing[2.5])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]',
				// Options (multi-select)
				'[&_optgroup]:font-semibold',
				// Typography
				'text-base/6 placeholder:text-zinc-500 sm:text-sm/6',
				!modelValue ? 'text-zinc-500' : 'text-zinc-950 dark:text-white dark:*:text-white',
				// Border
				'border border-zinc-950/10 data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20',
				// Background color
				'bg-transparent dark:bg-white/5 dark:*:bg-zinc-800',
				// Hide default focus styles
				'focus:outline-none',
				// Invalid state
				'data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500 data-[invalid]:dark:border-red-600 data-[invalid]:data-[hover]:dark:border-red-600',
				// Disabled state
				'data-[disabled]:border-zinc-950/20 data-[disabled]:opacity-100 dark:data-[hover]:data-[disabled]:border-white/15 data-[disabled]:dark:border-white/15 data-[disabled]:dark:bg-white/[2.5%]',
			])"
			@change="onChange"
			@focusin="focused = true"
			@focusout="focused = false"
			@mouseover="hovered = true"
			@mouseout="hovered = false">
			<slot />
		</select>
		<span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
			<svg
				class="size-5 stroke-zinc-500 group-has-[[data-disabled]]:stroke-zinc-600 sm:size-4 dark:stroke-zinc-400 forced-colors:stroke-[CanvasText]"
				viewBox="0 0 16 16"
				aria-hidden="true"
				fill="none">
				<path
					d="M5.75 10.75L8 13L10.25 10.75"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					d="M10.25 5.25L8 3L5.75 5.25"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round" />
			</svg>
		</span>
	</span>
</template>

<script lang="ts">
	export default { name: 'VSelect' }// name the component
</script>

<script setup lang="ts">
	import { 
		clsx
	} from 'clsx'

	import {
		inject,
		nextTick,
		ref
	} from 'vue'

	const props = defineProps({
		modelValue: {
			type: [String, Number, Array],
			default: null
		},
		className: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		invalid: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: false
		}
	})

	const emit = defineEmits([
		'update:modelValue'
	])

	const fieldDisabled: boolean = inject('fieldDisabled', null) || inject('fieldsetDisabled', null) || props.disabled
	const focused = ref(false)
	const hovered = ref(false)

	// update modeled value
	const onChange = async ({ target }: any) => {
		emit('update:modelValue', target.value)
		await nextTick()
		return
	}
</script>