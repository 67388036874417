<template>
	<DialogDescription
		as="h3"
		class="mb-6">
		<p class="mb-2">
			{{ t('account.unavailabilityDescription') }}
		</p>
		<p>{{ t('account.unavailabilityExample') }}</p>
	</DialogDescription>
	<form @submit.prevent="submitUnavailability">
		<div class="space-y-4">
			<!-- Date -->
			<tw-input
				v-model="date"
				id="date"
				ref="inputDate"
				:required="true"
				type="date"
				:label="localesStore.getLabel('361')"
				class="text-md" />

			<!-- Unavailability -->
			<v-field>
				<v-label class-name="block text-sm font-medium text-gray-700">
					{{ localesStore.getLabel('678') }}
				</v-label>
				<v-select
					class-name="border rounded-md border-gray-300 focus:ring-brand-500 focus:border-brand-500 !text-gray-900"
					v-model="unavailability"
					name="unavailability">
					<option
						v-for="option in options"
						:value="option.value"
						:key="option.value">
						{{ option.label }}
					</option>
				</v-select>
			</v-field>

			<!-- Start time -->
			<tw-input
				v-model="timeStart"
				id="timeStart"
				ref="inputTimeStart"
				:required="true"
				type="time"
				:label="localesStore.getLabel('144')" />

			<!-- End time -->
			<tw-input
				v-model="timeEnd"
				id="timeEnd"
				ref="inputTimeEnd"
				:required="true"
				type="time"
				:label="localesStore.getLabel('322')" />
		</div>
		<div
			v-if="error"
			class="text-red-500 mt-4 -mb-16">
			{{ errorMsg }}
		</div>
		<div class="mt-20">
			<tw-button
				:disabled="loading"
				:loading="loading"
				type="submit"
				class="btn-base btn-brand w-full">
				{{ t('account.submitUnavailability') }}
			</tw-button>
		</div>
	</form>
</template>

<script lang="ts">
	export default { name: "ChangeEmailForm" } 	// name the component
</script>

<script setup lang="ts">
	import { // components
		DialogDescription
	} from '@headlessui/vue'

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore as _localesStore
	} from '@/store/locales'

	import {
		Notification
	} from '@/types'

	import { // stores
		store as _userStore
	} from '@/store/user'
	
	import { // vue
		ref,
		defineProps,
		onMounted
	} from 'vue'

	import {
		modifyUnavailability
	} from '../mutations'

	const {t} = useI18n()

	const props = defineProps({
		contact: {
			type: Object,
			default: () => {return {}}
		},
		data: {
			type: Object,
			default: () => {return {}}
		}
	})

	const options = [
		{
			label: t('account.allDay'),
			value: "All Day"
		},
		{
			label: t('account.partialDay'),
			value: "Partial Day"
		}
	]

	const date = ref('')
	const inputDate = ref<null | {valid: Boolean}>(null)

	const unavailability = ref('')

	const timeStart = ref('')
	const inputTimeStart = ref<null | {valid: Boolean}>(null)

	const timeEnd = ref('')
	const inputTimeEnd = ref<null | {valid: Boolean}>(null)

	const loading = ref(false)
	const error = ref(false)
	const errorMsg = ref('')


	const localesStore = _localesStore()
	const userStore = _userStore()

	const emit = defineEmits(['closeUnavailabilityModal'])

	onMounted(() => {
		// Values from the row selected
		if(Object.keys(props.data).length > 0) {
			const [month, day, year] = props.data['contacts__DAYSOFF__contactID::date'].split('/')
			date.value = `${year}-${month}-${day}`
			unavailability.value = props.data['contacts__DAYSOFF__contactID::availability_display']
			timeStart.value = props?.data['contacts__DAYSOFF__contactID::time_start']
			timeEnd.value = props?.data['contacts__DAYSOFF__contactID::time_end']
		}
	})
	
	async function submitUnavailability() {
		if (!date.value) {
			error.value = true
			errorMsg.value = "Date is required"
		}


		const [year, month, day] = date.value.split('-')
		const data = JSON.stringify({
			action: Object.keys(props.data).length === 0 ? "create" : "update",
			contactID: userStore.user.contactId,
			unavailabilityID: props.data['contacts__DAYSOFF__contactID::_kp_daysoff_id'],
			date: `${month}/${day}/${year}`,
			unavailability: unavailability.value,
			timeStart: timeStart.value,
			timeEnd: timeEnd.value
		})

		try{
			loading.value = true

			const result = await modifyUnavailability(data)

			const scriptResult = JSON.parse(result.response.scriptResult)

			const fmpError = scriptResult.errorCode !== 0

			if (fmpError) {
				throw new Error(scriptResult.errorMessage)
			}

			const notification: Notification = {
				title: t('placeholders.success'),
				subtitle: t("placeholders.unavailabilitySubmit"),
				icon: 0
			}

			emit('closeUnavailabilityModal', notification)

			date.value = ""
			unavailability.value = ""
			timeStart.value = ""
			timeEnd.value = ""
			error.value = false
			errorMsg.value = ""

		}
		catch (e) {
			error.value = true
			errorMsg.value = e.message
		}
		finally {
			loading.value = false
		}
	}
</script>