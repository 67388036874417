<template>
	<div class="flex flex-col min-h-screen justify-between">
		<div class="flex h-full">
			<div class="flex flex-1 flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
				<div class="mx-auto w-full max-w-sm lg:w-96">
					<div class="mb-2 content-center">
						<img
							src="@/assets/images/Crystal_Wedding_Logo_Primary_v2.svg"
							alt="Heartful Logo"
							class="flex h-64 w-64 mx-auto">
					</div>

					<LoginForm 
						v-if="currentRoute == 'Login'" />
					<ResetPasswordForm 
						v-else-if="currentRoute == 'ResetPassword'" />
					<ResetPasswordSuccess 
						v-else-if="currentRoute == 'ResetPasswordSuccess'" />
					<UpdatePasswordForm 
						v-else-if="currentRoute == 'UpdatePassword'" />
				</div>
			</div>
			<div class="relative hidden w-0 flex-1 min-h-screen lg:block overflow-hidden">
				<img
					src="@/assets/images/unnamed1.jpg"
					alt="Heartful Logo"
					class="w-full h-full absolute inset-0 object-cover">
			</div>
		</div>
	</div>	
</template>

<script lang="ts">
	export default { name: 'LoginMain' } 	// name the component
</script>

<script setup lang="ts">
	import LoginForm from './components/LoginForm.vue'
	import ResetPasswordForm from './components/ResetPasswordForm.vue'
	import ResetPasswordSuccess from './components/ResetPasswordSuccess.vue'
	import UpdatePasswordForm from './components/UpdatePasswordForm.vue'

	import {
		useRoute,
		useRouter
	} from 'vue-router'

	import { 
		store as _userStore
	} from '@/store/user'

	import {
		computed,
		onBeforeMount
	} from 'vue'

	const router = useRouter()
	const userStore = _userStore()

	const currentRoute = computed(() => {
		return useRoute().name
	})

	onBeforeMount(async () => {
		try {
			// check for existing login & reroute
			if(userStore.user.loggedIn && !userStore.user.isPasswordChangeNeeded) {
				router.push({name: "Jobs"})
			}
		}
		catch(e) {
			console.log(e)
		}
	})


</script>
<style scoped>
	.min-h-260 {
		min-height: 65rem
	}
</style>