<template>
	<div class="flex flex-col gap-y-6 shadow rounded-md  min-w-full min-h-full p-5 bg-white">
		<!-- top section -->
		<div>
			<div class="col-span-3 flex justify-start gap-x-2 align-middle">
				<div>
					<BuildingOfficeIcon class="w-6" />
				</div>
				<router-link
					class="underline"
					:to="{name: 'JobDetails', params: {recordId: props.role.recordID}}">
					{{ props.role.companyDisplay }}
				</router-link>
			</div>
			<!-- Section under title -->
			<div class="col-span-3 flex align-top justify-start gap-x-1 text-sm">
				<span>{{ props.role.dateStart }}</span>
				<span>{{ `(${_localesStore.getLabel(props.role.dateDayCode)})` }}</span>
				<span>-</span>
				<span>{{ props.role.typeDisplay }}</span>
				<span>-</span>
				<span>{{ props.role.categoryDisplay }}</span>
			</div>
		</div>
		<!-- Section for times and location -->
		<div class="col-span-3 flex justify-start gap-x-5 min-h-13">
			<div>
				<span>{{ _localesStore.getLabel('233') }}</span>
				<p class="text-sm">
					{{ props.role.timeStart.slice(0,5) }}
				</p>
			</div>
		</div>
		<!-- Section for Errors -->
		<div>
			<span
				class="text-sm text-red-500"
				v-if="error">{{ errorMsg }}</span>
		</div>
		<!-- Section for the buttons -->
		<div class="col-span-3 flex gap-y-2 flex-col justify-around gap-x-5 pt-4 xl:flex-row">
			<tw-button
				:disabled="departureDisabled"
				:loading="loading"
				@click="submitDepartureTime"
				:class="departureDisabled ? 'button bg-gray-300 w-full text-black' : 'button bg-brand-500 w-full text-white hover:btn-brand'">
				{{ departureLabel }}
			</tw-button>
			<tw-button
				:disabled="arrivalDisabled"
				:loading="loading"
				@click="submitArrivalTime"
				:class="arrivalDisabled ? 'button bg-gray-300 w-full text-black' : 'button bg-brand-500 w-full text-white hover:btn-brand'">
				{{ arrivalLabel }}
			</tw-button>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobRoleTimeCard' }
</script>

<script setup lang="ts">

	import {
		computed,
		defineProps,
		ref,
		onMounted
	} from 'vue'
	
	import { 
		localesStore
	} from '@/store/locales'

	import {
		useI18n
	} from 'vue-i18n'
	import {
		enterRoleTime
	} from '../mutations'

	const {t} = useI18n()
	const _localesStore = localesStore()

	const props = defineProps({
		role: {
			type: Object,
			default: () => {return {}}
		}
	})

	const loading = ref(false)
	const departureDisabled = ref(false)
	const arrivalDisabled = ref(false)
	const error = ref(false)
	const errorMsg = ref("")
	const departureTime = ref("")
	const arrivalTime = ref("")

	onMounted(() => {
		if(props.role.departureTime) {
			departureTime.value = props.role.departureTime
			departureDisabled.value = true
		}
		if(props.role.arrivalTime) {
			arrivalTime.value = props.role.arrivalTime
			arrivalDisabled.value = true
		}
	})

	const departureLabel = computed(() => {
		if(!departureTime.value) {
			return t("staffJobs.submitDeparture")
		}
		return `${t('staffJobs.departed')}: ${departureTime.value.slice(0, 5)}`
	})

	const arrivalLabel = computed(() => {
		if(!arrivalTime.value) {
			return t("staffJobs.submitArrival")
		}
		return `${t('staffJobs.arrived')}: ${arrivalTime.value.slice(0, 5)}`
	})

	async function submitDepartureTime() {
		try{
			loading.value = true
			departureDisabled.value = true

			const time = new Date()
			const timeString = time.toTimeString().split(" ")[0]
			const params = JSON.stringify({
				"time": timeString,
				"roleID": props.role.roleID,
				"type": "departure"
			})
			const res = await enterRoleTime(params)
			const fmRes = JSON.parse(res.response.scriptResult)

			if (fmRes.errorCode !== 0) {
				throw new Error(fmRes.errorMessage)
			}

			departureTime.value = timeString
			departureDisabled.value = true
		}catch(e: any) {
			error.value = true
			errorMsg.value = e.message
			departureDisabled.value = false
		}finally{
			loading.value = false
		}
	}

	async function submitArrivalTime() {
		try{
			loading.value = true
			departureDisabled.value = true

			const time = new Date()
			const timeString = time.toTimeString().split(" ")[0]
			const params = JSON.stringify({
				"time": timeString,
				"roleID": props.role.roleID,
				"type": "arrival"
			})
			const res = await enterRoleTime(params)
			const fmRes = JSON.parse(res.response.scriptResult)
			
			if (fmRes.errorCode !== 0) {
				throw new Error(fmRes.errorMessage)
			}

			arrivalTime.value = timeString
			arrivalDisabled.value = true
		}catch(e: any) {
			error.value = true
			errorMsg.value = e.message
			arrivalDisabled.value = false
		}finally{
			loading.value = false
		}
	}

</script>