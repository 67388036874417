import axios from 'axios'

 function enterRoleTime(params: any) {
	const postData = {
		fieldData : {
			postData: params
		},
		script : "Job Role - Enter Time",
		'script.param' : params
	}
	return axios.post(`runScript.php`, postData)
		.then((response) => {
			return response.data
		})
		.catch((e) => {
			if(e.response.data.messages[0].message) {
				// Return FM Error Response
				return e.response.data
			}else{
				// Format Unknown Error Like a FM Error
				return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
			}
		})
}

export {
	enterRoleTime
}